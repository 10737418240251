<template>
  <section class="bulk-details container">
    <h1 class="text__center padding__md">Batch Order Details</h1>
    <div class="form__grid margin__sm">
      <div>
        By filling out the information to the right you are selecting a default information for each order in this batch order.
        <ul class="list__normal">
          <li>Bill to may be changed for any individual order</li>
          <li>Thank you note may not be required nor used on every order. Please provide a default for when it is needed. It may be changed for any given order.</li>
          <li>Billing method may be changed for any individual order</li>
          <li>Comments apply to the entire batch order and are used as a default for each order. They may be changed for any given order.</li>
        </ul>
      </div>
      <section class="bulk-details--form">
        <label :class="[errors.name ? 'form--error': null]">
          <span class="text__bold">Batch Name*</span>
          <input type="text" v-model="form.name" placeholder="batch name" />
        </label>
        <form-error :message="errors.name" />
        <label class="pos__rel billing" :class="[errors.billingUser ? 'form--error' : null]">
          <span class="text__bold">Bill To*</span>
          <div class="selected-user flex__between" @click="toggleUsers">
            <span>{{ selectedBillingUser ? selectedBillingUser.displayName : "none" }}</span>
            <font-awesome-icon v-if="!usersToggled" icon="caret-down" size="sm" />
            <font-awesome-icon v-if="usersToggled" icon="caret-up" size="sm" />
          </div>
          <div v-if="usersToggled" class="billing-search">
            <input type="text" v-model="search" placeholder="search users" />
            <div v-if="!users" class="flex__center">
              <p class="text__bold text__center">Loading Users</p>
            </div>
            <div v-else class="billto-list--container">
              <simplebar class="minor--overflow" data-simplebar-auto-hide="false">
                <div class="billto-list">
                  <label
                    class="billto-list--item"
                    v-for="user in filteredBillingUsers"
                    :key="user._id"
                  >
                    <input
                      type="radio"
                      v-model="form.billingUser"
                      :value="user._id"
                      @change="toggleUsers"
                    />
                    <span>{{ user.email }}</span>
                  </label>
                </div>
              </simplebar>
            </div>
          </div>
        </label>
        <form-error :message="errors.billingUser" />
        <label :class="[errors.billingMethod ? 'form--error': null]">
          <span class="text__bold">Billing Method*</span>
          <select v-model="form.billingMethod">
            <option value></option>
            <option value="Payroll">Payroll</option>
            <option value="Marketing Budget">Marketing Budget (if available)</option>
          </select>
        </label>
        <form-error :message="errors.billingMethod" />
        <label>
          Use Award Balance
          <select v-model="form.useAwardBalance">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
        </label>
        <label>
          <span class="text__bold">Thank You Note Default (included on all orders)</span>
          <div class="form--comments">
            <textarea
              v-model="form.thankYouNote"
              placeholder="Dear Mr. ..."
              rows="3"
              maxlength="500"
            />
            <canned @setMessage="setThankYou" />
          </div>
        </label>
        <label class="pos__rel">
          <span class="text__bold">Order Comments</span>
          <div class="form--comments">
            <textarea v-model="form.comments" rows="4" />
            <canned @setMessage="setComments" />
          </div>
        </label>
        <div class="flex__center margin__sm">
          <button class="btn btn__green text__md" @click="submit">Start Batch Order</button>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isValidated: true,
      usersToggled: false,
      form: {
        name: "",
        requester: "",
        requesterName: "",
        requesterEmail: "",
        requester2Email: "",
        billingUser: null,
        billingName: null,
        billingEmail: null,
        thankYouNote: "",
        billingMethod: "",
        useAwardBalance: false,
        comments: ""
      },
      errors: {
        name: null,
        billingUser: null,
        billingMethod: null
      },
      users: null,
      search: ""
    };
  },
  metaInfo: {
    title: "New Batch Order | WOW"
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    filteredUsers() {
      if (!this.users) return null;
      const fullAccess = ["admin", "super-admin", "asm"];
      if (fullAccess.includes(this.getUserDetails.permission)) {
        return this.users;
      }
      return this.users.filter(item => {
        return item.delegatedUsers.includes(this.getUserDetails._id);
      });
    },
    filteredBillingUsers() {
      if (!this.users) return null;
      if (!this.search) return this.filteredUsers;
      return this.filteredUsers.filter(user => {
        return user.displayName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    selectedBillingUser() {
      if (!this.users) return null;
      if (!this.form.billingUser) return null;
      return this.filteredUsers.filter(
        user => user._id === this.form.billingUser
      )[0];
    }
  },
  methods: {
    ...mapActions([
      "loadDelegates",
      "startSetIsSubmitting",
      "startCreateBulkOrder"
    ]),
    async submit() {
      this.validateForm();
      if (!this.isValidated) return null;
      this.startSetIsSubmitting({ bool: true, heading: "Creating" });
      const form = this.form;
      form.requester = this.getUserDetails._id;
      form.requesterName = this.getUserDetails.displayName;
      form.requesterEmail = this.getUserDetails.email;
      form.requester2Email = this.getUserDetails.email2;
      form.billingUser = this.selectedBillingUser._id;
      form.billingName = this.selectedBillingUser.displayName;
      form.billingEmail = this.selectedBillingUser.email;
      try {
        const order = await this.startCreateBulkOrder(form);
        this.startSetIsSubmitting({ bool: false });
        this.$router.push(`/batch-order/${order._id}/batch-items`);
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.name = !this.form.name
        ? "Please enter a name for this batch order"
        : null;
      this.errors.billingUser = !this.form.billingUser
        ? "Please select a person to bill to"
        : null;
      this.errors.billingMethod =
        this.form.billingMethod === "" || !this.form.billingMethod
          ? "Please select a billing method"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    },
    toggleUsers() {
      this.search = "";
      this.usersToggled = !this.usersToggled;
    },
    setComments(e) {
      this.form.comments = e.message;
    },
    setThankYou(e) {
      this.form.thankYouNote = e.message;
    }
  },
  async mounted() {
    try {
      this.users = await this.loadDelegates();
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped lang="scss">
.bulk-details {
  @include grid($cols: 1fr);
}
.bulk-details--form {
  @include grid($cols: 1fr, $row-gap: 15px);
  max-width: 700px;
  label {
    @include grid($cols: 1fr, $row-gap: 5px);
  }
}
.billto-list--container {
  padding: 8px 5px;
  border-radius: 3px;
}
.minor--overflow {
  height: 175px;
}
.selected-user {
  border: 1px solid $dark-blue;
  border-radius: 3px;
  padding: 10px;
  height: 2.75rem;
}
.billing-search {
  @include pos-abs(
    $pos: absolute,
    $top: calc(100% + 15px),
    $left: null,
    $right: 0,
    $width: 100%,
    $z: 10
  );
  @include grid($rows: 40px 1fr, $row-gap: 10px);
  background-color: $sky;
  padding: 10px;
  border-radius: 5px;
  @include shadow;
  height: 250px;
}
.billto-list {
  @include grid($cols: 1fr, $row-gap: 5px, $col-gap: 10px);
}
.billto-list label {
  @include grid($cols: 1fr 7fr, $col-gap: 10px, $align: center);
  padding: 5px 8px;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: lighten($sky, 10%);
  }
}
@media (min-width: $sm) {
  .billto-list {
    grid-template-columns: 1fr 1fr;
  }
  .billto-list label {
    grid-template-columns: 1fr 7fr;
  }
}
@media (min-width: $md) {
  .billto-list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>